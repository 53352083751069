import React, { createContext, useState } from 'react'
import { toast } from 'react-toastify';

import api from '../services/api'
export const ModalContext = createContext({})

function ModalProvider({ children }) {

    const [modalGlobal, stateModalGlobal] = useState(false)
    const [deleteItems, setDeleteItems] = useState(false)
    const [modal, setModal] = useState(false)


    const setStateModalGlobal = (value) => {
        stateModalGlobal(value)
    }

    const redirectToPage = (id, inventorySummary, str) => {

        if (inventorySummary) {
            localStorage.getItem("@ID_EDIT_STOCK")
            localStorage.setItem("@ID_EDIT_INVENTORY", true)
            setModal(true)
            return localStorage.setItem("@ID_EDIT_STOCK", id)
        }

        localStorage.removeItem("@ID_EDIT_INVENTORY")
        localStorage.removeItem("@ID_EDIT_STOCK")
        localStorage.setItem("@ID_EDIT_STOCK", id)
        setModal(true)

    }
    const changeStateModal = () => {

        setModal(!modal)

    }
    const removeCountages = async () => {

        const response = await api.delete(`countage/${localStorage.getItem("@ID_EDIT_STOCK")}`)

        if (response.status == 200) {
            window.location.href = "/dashboard/manager"
        }

    }
    const removeAllItems = (value) => {

        setDeleteItems(value)

    }



    return (
        <ModalContext.Provider value={{ setStateModalGlobal, removeAllItems, redirectToPage, changeStateModal, removeCountages, deleteItems, modalGlobal, modal }}>
            {children}
        </ModalContext.Provider>
    )

}

export default ModalProvider